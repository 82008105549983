import React from 'react'
import Footer from './Footer'
import './Certificate.css'

const Certificate = () => {
  return (
    <div className='cert'>
     <img src='img/certificate.webp' alt='cerificate'></img>
     <Footer/>
    </div>
  )
}

export default Certificate